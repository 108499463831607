import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import HiddenJs from "@material-ui/core/Hidden/HiddenJs";
import SEO from "../components/seo";
import Layout from "../components/layout";
import SimplePage from "../components/simple-page";

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: "80px",
    justifyContent: "space-around",
    [theme.breakpoints.down(767)]: {
      flexDirection: "column",
    },
  },
  spacing: {
    marginTop: 50,
  },
  smallImage: {
    width: "20vw",
    height: "20vw",
    [theme.breakpoints.down(767)]: {
      width: "90vw",
      height: "90vw",
      marginBottom: 40,
    },
  },
  imageWrapper: {
    width: "40vw",
    maxHeight: "40vw",
    [theme.breakpoints.down(767)]: {
      width: "90vw",
      maxHeight: "90vw",
    },
  },
  yourImage: {
    width: "20vw",
    [theme.breakpoints.down(767)]: {
      width: "90vw",
    },
  },
  yourStyle: {
    width: "20vw",
    [theme.breakpoints.down(767)]: {
      width: "90vw",
    },
  },
  yourCanvas: {
    width: "40vw",
    [theme.breakpoints.down(767)]: {
      width: "90vw",
    },
  },
  text: {
    fontSize: 24,
    textAlign: "center",
    [theme.breakpoints.down(767)]: {
      textAlign: "left",
      alignSelf: "flex-start",
      marginBottom: 20,
      fontSize: 26,
    },
  },
}));

const PhysicalPrintsPage = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      prints: file(relativePath: { eq: "physical-prints.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      dog: file(relativePath: { eq: "prints/dog.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      dogCanvas: file(relativePath: { eq: "prints/canvas_dog.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      dogPicasso: file(relativePath: { eq: "prints/picasso.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bridge: file(relativePath: { eq: "prints/bridge.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bridgeCanvas: file(relativePath: { eq: "prints/canvas_bridge.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bridgeScream: file(relativePath: { eq: "prints/scream.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      duke: file(relativePath: { eq: "prints/duke.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      dukeCanvas: file(relativePath: { eq: "prints/canvas_duke.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      dukeAmericanGirl: file(relativePath: { eq: "prints/american_girl.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      wedding: file(relativePath: { eq: "prints/wedding.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      weddingCanvas: file(relativePath: { eq: "prints/canvas_wedding.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      weddingSeatedNude: file(relativePath: { eq: "prints/seated_nude.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const images = [
    ["dog", "dogPicasso", "dogCanvas"],
    ["bridge", "bridgeScream", "bridgeCanvas"],
    ["duke", "dukeAmericanGirl", "dukeCanvas"],
    ["wedding", "weddingSeatedNude", "weddingCanvas"],
  ];

  return (
    <Layout>
      <SEO title="Physical Prints" />
      <SimplePage
        title="Physical Prints"
        description="Imagine it, create it."
        fluid={data.prints.childImageSharp.fluid}
      >
        <HiddenJs smDown>
          <div className={clsx(classes.wrapper, classes.spacing)}>
            <div className={clsx(classes.yourImage, classes.text)}>
              Your Image
            </div>
            <div className={clsx(classes.yourStyle, classes.text)}>
              Your Style
            </div>
            <div className={clsx(classes.yourCanvas, classes.text)}>
              Your Canvas
            </div>
          </div>
        </HiddenJs>
        {images.map(arr => (
          <div className={classes.wrapper} key={arr[0]}>
            <HiddenJs mdUp>
              <div className={clsx(classes.yourImage, classes.text)}>
                Your Image
              </div>
            </HiddenJs>
            <Img
              className={classes.smallImage}
              objectFit="cover"
              objectPosition="50% 50%"
              fluid={data[arr[0]].childImageSharp.fluid}
            />
            <HiddenJs mdUp>
              <div className={clsx(classes.yourStyle, classes.text)}>
                Your Style
              </div>
            </HiddenJs>
            <Img
              className={classes.smallImage}
              objectFit="cover"
              objectPosition="50% 50%"
              fluid={data[arr[1]].childImageSharp.fluid}
            />
            <HiddenJs mdUp>
              <div className={clsx(classes.yourCanvas, classes.text)}>
                Your Canvas
              </div>
            </HiddenJs>
            <Img
              className={classes.imageWrapper}
              objectFit="cover"
              objectPosition="50% 50%"
              fluid={data[arr[2]].childImageSharp.fluid}
            />
          </div>
        ))}
      </SimplePage>
    </Layout>
  );
};

export default PhysicalPrintsPage;
